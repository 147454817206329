import { Attr, HasMany, HasOne, Model } from "spraypaint";
import {
  ChangeRequestId,
  CounselingStatusId,
  DepotStatus,
  MoveStageDate,
  ShipmentType,
} from "types";
import { serviceStatusMap } from "constant/serviceStatusMap";
import { shipmentTypeLabelMap } from "constant/shipmentTypeLabelMap";
import { ApplicationRecord } from "./ApplicationRecord";
import { CrewTracker } from "./CrewTracker";
import { Customer } from "./Customer";
import { InventoryItem } from "./InventoryItem";
import { OrderAddress } from "./OrderAddress";
import { Service } from "./Service";
import { Survey } from "./Survey";
import { Claim } from "./Claim";
import { MoveType } from "./MoveType";
import { Entitlement } from "./Entitlement";
import { ChangeRequest } from "./ChangeRequest";
import { Location } from "./Location";
import { User } from "./User";
import { InventoryTracker } from "./InventoryTracker";
import { ServiceStatus } from "__generated__/types";

@Model()
export class Order extends ApplicationRecord {
  static jsonapiType = "orders";

  constructor(props?: any) {
    super(props);
    this.roomItemsWithClaims = this.roomItemsWithClaims.bind(this);
    this.itemClaims = this.itemClaims.bind(this);
  }

  @Attr() number!: string;

  @Attr() status!: DepotStatus;
  @Attr() counselingStatus!: CounselingStatusId;
  @Attr() entitlementWeight!: number;
  @Attr() estimatedWeight!: number;
  @Attr() actualWeight!: number;
  @Attr() actualWeightSource!: string;
  @Attr() proGearWeight!: number;
  @Attr() spouseProGearWeight!: number;
  @Attr() initialNetWeight!: number;
  @Attr() reweighNetWeight!: number;
  @Attr() personalPropertyWeight!: number;
  @Attr() sitRequested!: boolean;

  @Attr() deliveryDate!: string;
  @Attr() deliveryToDate!: string;
  @Attr() preferredDeliveryDate!: string;
  @Attr() plannedDeliveryDate!: string;
  @Attr() actualDeliveryDate!: string;

  @Attr() packDate!: string;
  @Attr() packToDate!: string;
  @Attr() preferredPackDate!: string;
  @Attr() plannedPackDate!: string;
  @Attr() actualPackDate!: string;

  @Attr() plannedLoadDate!: string;
  @Attr() actualLoadDate!: string;
  @Attr() loadDate!: string;
  @Attr() loadToDate!: string;
  @Attr() preferredLoadDate!: string;

  @Attr() shipmentType!: ShipmentType;
  @Attr() mtoOrderType!: string;

  @Attr() surveillance!: boolean;

  @HasOne() assignedUser!: User;
  @HasOne() origin!: OrderAddress;
  @HasOne() destination!: OrderAddress;
  @HasOne() crewTracker!: CrewTracker;
  @HasOne() customer!: Customer;
  @HasOne() inventoryTracker!: InventoryTracker;

  @HasMany() changeRequests!: ChangeRequest[];
  @HasOne() entitlement!: Entitlement;
  @HasOne() moveType!: MoveType;
  @HasMany() inventoryItems!: InventoryItem[];
  @HasMany() claims!: Claim[];
  @HasMany() services!: Array<Service>;
  @HasMany() locations!: Location[];
  @HasMany() surveys!: Survey[] | undefined;

  get isInOnboarding() {
    return this.moveType?.counselingStatus !== "COMPLETE";
  }

  get addresses(): OrderAddress[] {
    return [this.origin, this.destination];
  }

  //still not sure what this is but in the tests it's set to 25
  get cwt(): number {
    return 25;
  }

  serviceScheduledDate(status?: string): string | null {
    const moveStatus = status ?? this.status;

    const isPackStatus =
      moveStatus.includes("PACK") ||
      moveStatus.includes("SURVEY_COMPLETED") ||
      moveStatus.includes("PLANNED");
    const isLoadStatus = moveStatus.includes("LOAD");
    const isDeliveryStatus =
      moveStatus.includes("DELIVERY") ||
      (moveStatus.includes("IN_TRANSIT") && !moveStatus.includes("STORAGE"));

    if (isLoadStatus && !!this.plannedLoadDate) {
      return this.plannedLoadDate;
    } else if (isPackStatus && !!this.plannedPackDate) {
      return this.plannedPackDate;
    } else if (isDeliveryStatus && !!this.plannedDeliveryDate) {
      return this.plannedDeliveryDate;
    } else {
      return null;
    }
  }

  /**
   * Gets the most recently created scheduled survey service
   */
  get scheduledSurveyService() {
    const surveyServices = this.services.filter(
      (service) => service.type === "SURVEY"
    );
    if (surveyServices.length > 0) {
      return surveyServices.reduce((acc, curr) => {
        return acc.createdAt > curr.createdAt ? acc : curr;
      });
    }
  }

  get hasSurvey() {
    return this?.surveys && this?.surveys?.length > 0;
  }

  get pendingSurvey() {
    return this?.surveys?.filter((survey) => survey.isPendingApproval)?.[0];
  }

  get primarySurvey() {
    const primarySurveys = this?.surveys?.filter((survey) => survey.isPrimary);
    if (primarySurveys?.length && primarySurveys?.length > 1) {
      console.warn("Multiple primary surveys found");
    }
    return primarySurveys?.[0];
  }

  get shouldAllowCargoClaims() {
    return this.status === "DELIVERY_COMPLETED" || this.status === "COMPLETED";
  }

  get supportedServices(): string[] {
    return Object.keys(
      this.services.reduce(
        (acc: { [key: string]: boolean }, service: Service) => {
          acc[service.type] = true;
          return acc;
        },
        {}
      )
    );
  }

  get currentService(): Service | null {
    return (
      this.services.find(
        (service: Service) => serviceStatusMap[this.status] === service.type
      ) || null
    );
  }

  get counselingEntitlementsService() {
    return this.services.find(
      (service) =>
        service.type === "COUNSELING" && service.status !== "CANCELED"
    );
  }

  /**
   * Gets whether the order has entitlements counseling
   */
  get hasEntitlementsCounseling(): boolean {
    return this.counselingEntitlementsService !== undefined;
  }

  /**
   * Gets whether the order has completed the counseling entitlements
   * service. This means the user has gone through all the forms
   * and submitted.
   */
  get hasCompletedEntitlementsCounseling(): boolean {
    return this.counselingEntitlementsService?.status === "PERFORMED";
  }

  /**
   * Gets whether the order has a transportation service attached
   */
  get hasTransportation(): boolean {
    return (
      this.services.find(
        (service) =>
          service.type === "TRANSPORTATION" &&
          service.status !== ServiceStatus.Canceled
      ) !== undefined
    );
  }

  /**
   * Gets whether the order has a storage service attached
   */
  get hasStorage(): boolean {
    return (
      this.services.find(
        (service) =>
          service.type === "SITFIRSTDAYATDESTINATION" &&
          service.status !== ServiceStatus.Canceled
      ) !== undefined
    );
  }

  get hasPartialStorage(): boolean {
    return this.services.some(
      (service) =>
        service.type === "SITFIRSTDAYATDESTINATION" &&
        service.partialStorage &&
        service.status !== ServiceStatus.Canceled
    );
  }

  get hasStorageAtOrigin(): boolean {
    return (
      this.services.find(
        (service) =>
          service.type === "SITFIRSTDAYATORIGIN" &&
          service.status !== ServiceStatus.Canceled
      ) !== undefined
    );
  }
  /**
   * Gets the origin location timezone
   */
  get originTimeZone() {
    return this.locations.find((l) => l.type === "OriginLocation")?.timezone;
  }

  get hasReweighPending(): boolean {
    return (
      this.services.find(
        (service) =>
          service.type === "REWEIGH" &&
          !["PERFORMED", "CANCELED"].includes(service.completionStatus)
      ) !== undefined
    );
  }

  get moveCompletedTime(): string | null {
    const deliveryService = this.services.find(
      (service) => service.type === "DELIVERY"
    );

    if (deliveryService) {
      const { completionStatus, statusUpdatedAt, updatedAt } = deliveryService;
      return completionStatus === "PERFORMED"
        ? statusUpdatedAt || updatedAt
        : null;
    } else {
      return null;
    }
  }

  get completionDates(): ScheduledDate[] {
    return this.services
      .filter((service) => service.status === "Completed")
      .map((service) => ({
        status: service.type.toLowerCase(),
        date: service.scheduledDaterange!.begin,
      }));
  }

  get originLocation(): Location | null {
    return this.locations.find((l) => l.type === "OriginLocation") || null;
  }

  get destinationLocation(): Location | null {
    return this.locations.find((l) => l.type === "DestinationLocation") || null;
  }

  get moveStageDates(): MoveStageDate[] {
    const getLatestChangeRequest = (type: ChangeRequestId) => {
      return this.changeRequests
        .filter((request): request is ChangeRequest<"date"> => {
          return request.name === type;
        })
        .sort((resultA, resultB) => {
          return (
            new Date(resultB.updatedAt).getTime() -
            new Date(resultA.updatedAt).getTime()
          );
        })[0];
    };

    return [
      {
        label: "Pack",
        description: "moveStageDate.pack.description",
        changeRequestKey: "pack_date",
        changeRequestId: "PACK_DATE",
        preferredDate: this.preferredPackDate,
        scheduledDate: this.plannedPackDate,
        completedDate: this.actualPackDate,
        possibleStartDate: this.packDate,
        possibleEndDate: this.packToDate,
        pendingChangeRequest: getLatestChangeRequest("PACK_DATE"),
      },
      {
        label: "Load",
        description: "moveStageDate.load.description",
        changeRequestKey: "load_date",
        changeRequestId: "LOAD_DATE",
        preferredDate: this.preferredLoadDate,
        scheduledDate: this.plannedLoadDate,
        completedDate: this.actualLoadDate,
        possibleStartDate: this.loadDate,
        possibleEndDate: this.loadToDate,
        pendingChangeRequest: getLatestChangeRequest("LOAD_DATE"),
      },
      {
        label: "Delivery",
        description: "moveStageDate.delivery.description",
        changeRequestKey: "delivery_date",
        changeRequestId: "DELIVERY_DATE",
        preferredDate: this.preferredDeliveryDate,
        scheduledDate: this.plannedDeliveryDate,
        completedDate: this.actualDeliveryDate,
        possibleStartDate: this.deliveryDate,
        possibleEndDate: this.deliveryToDate,
        pendingChangeRequest: getLatestChangeRequest("DELIVERY_DATE"),
      },
    ];
  }

  get shipmentTypeLabel(): string {
    return shipmentTypeLabelMap[this.shipmentType] || `Shipment ${this.number}`;
  }

  /**
   * Returns the nested rooms->items structure needed to render in the application
   * @returns InventoryRooms
   */
  get inventoryRooms(): InventoryRooms {
    return [];
    // return Array.from(
    //   this.inventoryItems
    //     .reduce((roomsList, item) => {
    //       const itemInventoryRoom = item.inventoryRoom;
    //       const roomId = itemInventoryRoom.id;
    //
    //       if (roomId && item.inventoryRoom.id) {
    //         const listedRoom = roomsList.get(item.inventoryRoom.id);
    //         if (!listedRoom) {
    //           roomsList.set(roomId, {
    //             ...itemInventoryRoom,
    //             items: [item],
    //           });
    //         } else {
    //           listedRoom.items.push(item);
    //         }
    //       }
    //
    //       return roomsList;
    //     }, new Map<string, OrderRoom>())
    //     .values()
    // );
  }

  /**
   * Returns a filtered list of rooms only containing items with claims
   * @returns InventoryRooms
   */
  get inventoryRoomsWithClaims(): InventoryRooms {
    return this.inventoryRooms
      .filter(this.roomItemsWithClaims)
      .reduce<InventoryRooms>((inventoryRooms, room) => {
        const itemsWithClaims = room.items.filter(this.itemClaims);
        if (itemsWithClaims.length > 0) {
          inventoryRooms.push({
            id: room.id,
            roomName: room.roomName,
            items: itemsWithClaims,
          });
        }
        return inventoryRooms;
      }, []);
  }

  /**
   * Determine whether or not the specified `room` has a claim on any of its items
   * @param room
   * @returns boolean
   */
  roomItemsWithClaims(room: OrderRoom) {
    return room.items.filter(this.itemClaims).length > 0;
  }

  /**
   * Return the associated claim for an item
   * @param inventoryItem
   * @returns Claim | undefined
   */
  itemClaims(inventoryItem: InventoryItem) {
    return this.claims.find(
      (claim) =>
        claim.lotNumber === inventoryItem?.lotNumber &&
        claim.tagNumber === inventoryItem.tagNumber
    );
  }

  sortChangeRequests() {
    this.changeRequests.sort((a: ChangeRequest, b: ChangeRequest): number => {
      return 1;
    });
  }

  submittedChangeRequests(): ChangeRequest[] {
    return this.changeRequests.filter((changeRequest: ChangeRequest) => {
      return changeRequest.status === "SUBMITTED";
    });
  }

  activeChangeRequest(name: ChangeRequestId): ChangeRequest | null {
    return (
      this.changeRequests.find((changeRequest: ChangeRequest) => {
        // @TODO This logic/naming should be updated
        return (
          changeRequest.status !== "NOT_VALID" && changeRequest.name === name
        );
      }) || null
    );
  }
}

type ScheduledDate = {
  status: string;
  date: string | null;
};

/**
 * The non-normalized structure we need to render an orders rooms
 * along with its nested items/etc.
 */
export type OrderRoom = {
  roomName: string;
  id?: string;
  items: Array<InventoryItem>;
};

export type InventoryRooms = OrderRoom[];
